<template>
  <div class="">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Banking'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>
<!--    Build Table from records Data-->
    <div class="table-responsive w-full">

      <vs-table-modified v-show="records.length > 0" ref="table"
                         :data="records">
        <template slot="thead">
<!--          <vs-th >{{$t('Customer')}}</vs-th>-->
<!--          <vs-th >{{$t('Address')}}</vs-th>-->
<!--          <vs-th >-->
<!--            <div class="vs-col">-->
<!--              <p class="">{{ $t('City') }}</p>-->
<!--              <p class="">{{ $t('PostalCode') }}</p>-->
<!--            </div>-->
<!--          </vs-th>-->
          <vs-th >{{$t('RecipientSender')}}</vs-th>
          <vs-th >{{$t('Amount')}}</vs-th>
          <vs-th >{{$t('Date')}}</vs-th>
<!--          <vs-th >{{$t('Actions')}}</vs-th>-->
        </template>
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="records" :key="indextr" v-for="(record, indextr) in data">

<!--            <vs-td>-->
<!--              <div class="vs-col">-->
<!--                <p>{{ record.customer_id || '-' }}</p>-->
<!--                <p>{{ record.name }}</p>-->
<!--              </div>-->
<!--            </vs-td>-->
<!--            <vs-td>-->
<!--              <p class="font-medium truncate" style="font-size: 12px;">-->
<!--                {{ record.address || '-' }}<span v-if="record.house_no"> {{ record.house_no }}</span>-->
<!--              </p>-->
<!--            </vs-td>-->
<!--            <vs-td>-->
<!--              <div class="vs-col">-->
<!--                <p class="font-medium truncate">{{ record.city || '-' }}</p>-->
<!--                <p class="font-medium truncate">{{ record.postal_code }}</p>-->
<!--              </div>-->
<!--            </vs-td>-->
            <vs-td>
              <p class="font-medium truncate">{{ record.recipient_sender }}</p>
            </vs-td>
            <vs-td>
              <p class="font-medium truncate">{{ record.amount | germanNumberFormat }}</p>
            </vs-td>
            <vs-td>
              <p class="font-medium truncate">{{ record.date | onlyDateFormat }}</p>
            </vs-td>
<!--            <vs-td>-->
<!--              <div class="flex flex-row w-full items-center justify-center space-x-2">-->
<!--                <vs-button color="success" class="px-2 py-1"-->
<!--                           @click="selectRecordForEdit(record)"-->
<!--                           icon="edit">{{ $t("Edit") }}</vs-button>-->
<!--                <vs-button-->
<!--                  v-if="record.customer_id"-->
<!--                  color="warning" class="px-2 py-1"-->
<!--                           @click="selectRecordForEdit(record, true)"-->
<!--                           icon="close">{{ $t("Unmap") }}</vs-button>-->
<!--                <vs-button-->
<!--                  color="danger" class="px-2 py-1"-->
<!--                           @click="deleteBankRecord(record)"-->
<!--                           icon="delete">{{ $t("Delete") }}</vs-button>-->
<!--              </div>-->
<!--            </vs-td>-->

          </vs-tr>
          </tbody>
        </template>
        <template slot="footer">
        </template>
      </vs-table-modified>
<!--      <div class="flex flex-col w-full justify-center items-center">-->
<!--        <div class="mb-1 sm:mb-2">-->
<!--          <label for="remarks" class="inline-block mb-1 font-medium">{{ $t("Remarks") }}<span class="text-danger">*</span></label>-->
<!--          <input-->
<!--            placeholder=""-->
<!--            required=""-->
<!--            type="text"-->
<!--            v-model="remarks"-->
<!--            class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"-->
<!--            id="remarks"-->
<!--            name="remarks"-->
<!--          />-->
<!--        </div>-->
<!--        <vs-button @click="startTopUp">{{ $t('TopUp') }}</vs-button>-->
<!--      </div>-->
    </div>
<!--    <div>-->
<!--      <vx-modal v-if="selectedRecord" @close="onCloseMapCustomerDialog" component-class="modal-container-7 banking-custom-height">-->
<!--        <template slot="header"></template>-->
<!--        <template slot="body" >-->
<!--          <div class="flex flex-col p-6 justify-center items-center">-->
<!--            <p style="font-size: 20px;" class="pb-6">-->
<!--            <span>{{ $t("MapCustomer") }}</span>-->
<!--            <strong>-->
<!--              {{ selectedRecord.recipient_sender }}-->
<!--            </strong>-->
<!--            </p>-->
<!--            <dynamic-customers-drop-down-->
<!--              :show-clear="false"-->
<!--              :placeholder="$t('SearchCustomer')"-->
<!--              :selected-item="selectedCustomer || {}"-->
<!--              @on-item-selected="onCustomerChanged">-->
<!--            </dynamic-customers-drop-down>-->
<!--            <div class="vs-row flex justify-center px-4 mt-6">-->
<!--              <vs-button size="large" class="ml-4 mr-4" color="danger" @click="onCloseMapCustomerDialog()">{{-->
<!--                  $t('Close')-->
<!--                }}-->
<!--              </vs-button>-->
<!--              <vs-button size="large" class="ml-2" color="primary" @click="mapCustomer()">{{ $t('MapCustomer') }}</vs-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </template>-->
<!--        <template #footer></template>-->
<!--      </vx-modal>-->
<!--    </div>-->
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown.vue'
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: "DeletedBankRecordsList",
  components: {
    DynamicCustomersDropDown,
    VxModal,
  },
  data() {
    return {
      selectedRecord: null,
      selectedCustomer: null,
      remarks: "",
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Deleted Records', i18n: 'DeletedRecords', active: true },
      ],
    };
  },
  computed: {
    records() {
      return this.$store.state.banking.records;
    }
  },
  methods: {
    onCloseMapCustomerDialog() {
      this.selectedRecord = null;
      this.selectedCustomer = null;
    },
    onCustomerChanged(c) {
      this.selectedCustomer = c
    },
    selectRecordForEdit(record, isDelete = false) {
      if(isDelete) {
        this.deleteMapCustomer(record);
      } else {
        this.selectedRecord = record;
        this.selectedCustomer = null
      }
    },
    deleteBankRecord(record) {
      let payload = {
        "customer_id": record.customer_id,
        "status_type": 2,
        "bank_record_id": record.bank_record_id,
      }
      this.$vs.loading()
      this.$store.dispatch('banking/mapCustomer', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.fetchRecords();
        this.onCloseMapCustomerDialog();
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
    },
    deleteMapCustomer(record) {
      let payload = {
        "customer_id": record.customer_id,
        "rm_customer_id": 1,
        "bank_record_id": record.bank_record_id,
      }
      this.$vs.loading()
      this.$store.dispatch('banking/mapCustomer', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.fetchRecords();
        this.onCloseMapCustomerDialog();
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
    },
    mapCustomer() {
      let payload = {
        "customer_id": this.selectedCustomer.customer_id,
        "bank_record_id": this.selectedRecord.bank_record_id,
      }
      this.$vs.loading()
      this.$store.dispatch('banking/mapCustomer', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.fetchRecords();
        this.onCloseMapCustomerDialog();
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
    },
    async fetchRecords() {

      this.$vs.loading()
      try {
        await this.$store.dispatch('banking/fetchBankRecords', {
          status_type: 2
        });
      } catch (e) {
        console.log(e);
      }
      this.$vs.loading.close();
    },
    startTopUp() {
      if(!this.remarks) {
        this.$vs.notify({
          title: 'Error',
          text: 'Please enter remarks',
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
        return false
      }
      let data = [];
      for (const record of this.records) {
        if (record.customer_id) {
          data.push({
            "customer_id": record.customer_id,
            "transaction_mode": "bank_transfer",
            "value": record.amount,
            "remarks": this.remarks,
            "bank_record_id": record.bank_record_id,
          })
        }
      }

      let finalData = {
        "top_up_data": JSON.stringify(data),
      }

      this.$vs.loading()
      this.$store.dispatch('customer/processMultiCustomerWalletAmount', finalData)
        .then((data) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: `Updated ${data.data.success_top_up_customers.length} customers`,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.fetchRecords();
          this.remarks = "";
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  mounted() {
    this.fetchRecords();
  }
}
</script>

<style >
.banking-custom-height {
  height: 700px;
}
</style>
